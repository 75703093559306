<template>
  <div>
    <div class="relative block lg:hidden z-[9999]">
      <div
        class="fixed inset-0 top-0 z-20 h-16 transition-all"
        :class="
          scrollPosition >= 80 || isStorePage
            ? 'bg-white shadow'
            : 'bg-gradient-to-b	from-[#00000054] to-[#0000000f]'
        "
      >
        <nav
          class="container flex items-center justify-between h-16"
          :class="[scrollPosition >= 80 || isStorePage ? 'pt-0' : '']"
        >
          <a
            href="#mmenu"
            tabindex="0"
            aria-label="Mobile sidebar mmenu toggle"
            class="z-10 text-xs font-semibold text-white md:text-base lg:text-xl hover:no-underline focus:no-underline hover:text-primary-500 focus:outline-none"
            @click.prevent="open"
          >
            <MobileMenuIcon
              class="w-6 h-6"
              role="Mobile menu trigger item icon"
              :class="
                isProductPage && (scrollPosition >= 80 || isStorePage)
                  ? 'text-black'
                  : scrollPosition >= 80 || isStorePage
                  ? 'text-black'
                  : 'text-white'
              "
            />
          </a>

          <a
            class="absolute flex items-center justify-center transform -translate-s-1/2 left-[48%]"
            @click.prevent="onClickLink($i18nPath('/'))"
            href="#"
            :aria-label="`MBP MOTO s.r.l ${$t('common.home_title')}`"
          >
            <NuxtImg
              v-show="scrollPosition >= 80 || isStorePage"
              alt="Morbidelli | MBP MOTO s.r.l"
              src="/morbidelli-dark.png"
              height="35"
              width="146"
              densities="x1"
              sizes="146px"
              class="transition-all h-[35px]"
            />
            <NuxtImg
              v-show="scrollPosition < 80 && !isStorePage"
              alt="Morbidelli | MBP MOTO s.r.l"
              src="/morbidelli-light.png"
              height="35"
              width="146"
              densities="x1"
              sizes="146px"
              class="transition-all h-[35px]"
            />
          </a>

          <div class="flex">
            <LazyFlyoutMenu
              v-if="products.length > 0"
              is-product
              :link-title="$t('common.models')"
            />

            <!-- <UserDropdown v-if="store.enableShop" /> -->
          </div>
        </nav>
      </div>
      <nav v-show="visible" id="mmenu" ref="mmenu">
        <ul>
          <!-- Home -->
          <li
            :class="isActive('home') ? 'is-active' : ''"
            @click.prevent="onClickLink($i18nPath('/'))"
          >
            <a
              href="#"
              :aria-label="$t('common.home_title')"
              v-text="$t('common.home_title')"
            />
            <div></div>
          </li>

          <!-- Stores -->
          <!-- v-if="store.enableShop" -->
          <li
            :class="isActive('stores') ? 'is-active' : ''"
            @click.prevent="onClickLink($i18nPath(`/stores`))"
          >
            <a
              :aria-label="$t('common.stores')"
              href="#"
              v-text="$t('common.stores')"
            />
            <div></div>
          </li>

          <!-- News -->
          <li
            v-if="currentSite && currentSite.news"
            :class="isActive('news') ? 'is-active' : ''"
            @click.prevent="onClickLink($i18nPath('/news'))"
          >
            <a
              :aria-label="$t('common.news')"
              href="#"
              v-text="$t('common.news')"
            />
            <div></div>
          </li>

          <!-- About us -->
          <li
            :class="isActive('about-us') ? 'is-active' : ''"
            @click.prevent="onClickLink($i18nPath('/about-us'))"
          >
            <a
              :aria-label="$t('common.about_us')"
              href="#"
              v-text="$t('common.about_us')"
            />
            <div></div>
          </li>

          <!-- Become a dealer -->

          <li
            v-if="
              currentSiteSettings &&
              currentSiteSettings.become_a_partner &&
              store.country === 'int'
            "
            @click.prevent="onClickLink($i18nPath(`/become-a-distributor`))"
            :class="isActive('become-a-distributor') ? 'is-active' : ''"
          >
            <a
              href="#"
              :aria-label="$t('common.become_a_distributor')"
              v-text="$t('common.become_a_distributor')"
            />
            <div></div>
          </li>
          <li
            v-else-if="
              currentSiteSettings && currentSiteSettings.become_a_partner
            "
            :class="isActive('become-a-dealer') ? 'is-active' : ''"
            @click.prevent="onClickLink($i18nPath(`/become-a-dealer`))"
          >
            <a
              href="#"
              :aria-label="$t('common.become_a_dealer')"
              v-text="$t('common.become_a_dealer')"
            />
            <div></div>
          </li>

          <!-- Shop online -->
          <li
            v-if="store.enableShop"
            :class="isActive('search') ? 'is-active' : ''"
            @click.prevent="onClickLink($i18nPath(`/search`))"
          >
            <a
              :aria-label="$t('common.shop_online')"
              href="#"
              v-text="$t('common.shop_online')"
            />
            <div></div>
          </li>
        </ul>
        <div
          class="w-full h-[60px] bg-white flex justify-end items-center absolute top-1 right-4 z-50"
        >
          <button @click="close" aria-label="close Button">
            <CloseIcon class="h-9 w-h-9 text-secondary" />
          </button>
        </div>
        <div
          class="absolute z-30 py-4 border-t left-4 right-4 bottom-5 border-secondary-light border-opacity-20"
        ></div>
      </nav>
    </div>
    <!-- Locale selector -->
    <div class="fixed bottom-4 left-4 z-[99999999]">
      <LazyLocaleSelector
        v-show="isMenuShow"
        name="headermobile"
        position="bottom"
        class="locale-selector"
      />
    </div>
  </div>
</template>

<script setup>
import { useMainStore } from "@/store/index";
import CloseIcon from "@/assets/svg/heroicons/x.svg?component";
import AngleDown from "@/assets/svg/heroicons/angle-down.svg?component";
import { useTranslation } from "i18next-vue";
import MobileMenuIcon from "@/assets/svg/heroicons/mobile-menu.svg?component";
import MmenuLight from "mmenu-light/esm/core/index";

const store = useMainStore();
const route = useRoute();
const router = useRouter();
const { t } = useTranslation();

const visible = ref(false);
const isProductPage = ref(false);
const isStorePage = ref(false);
const isMenuShow = ref(false);
const scrollPosition = ref(0);
const mmenu = ref(null);
const drawer = ref(null);

const products = store.products;
const categories = store.categories;
const currentSite = store.currentSite;
const currentSiteSettings = store.currentSiteSettings;

watch(
  router.currentRoute,
  (newValue, oldValue) => {
    if (newValue.name == "country-products-slug") {
      isProductPage.value = true;
    } else {
      isProductPage.value = false;
    }
    if (newValue.name == "country-stores") {
      isStorePage.value = true;
    } else {
      isStorePage.value = false;
    }
  },
  { deep: true }
);

const loadModule = () => {
  if (mmenu.value) {
    const menu = new MmenuLight(mmenu.value, "(max-width: 1024px)");
    /* eslint-disable-next-line */
    const navigator = menu.navigation({
      title: t("common.menu"),
    });
    drawer.value = menu.offcanvas({
      position: store.isRtl ? "right" : "left",
    });
  }
};

onMounted(() => {
  isProductPage.value = window.location.href.includes("/products/");
  isStorePage.value = window.location.href.includes("/stores");

  loadModule();

  window.addEventListener("scroll", updateScroll);

  var elements = document.querySelectorAll(".mm-ocd__backdrop");

  elements[0].addEventListener("touchend", onToggleMenu);
  elements[0].addEventListener("mouseup", onToggleMenu);

  visible.value = true;
});

onUnmounted(() => {
  window.removeEventListener("scroll", updateScroll);
  removeListener();
});

const removeListener = () => {
  var elements = document.querySelectorAll(".mm-ocd__backdrop");

  elements[0].removeEventListener("touchend", onToggleMenu);
  elements[0].removeEventListener("mouseup", onToggleMenu);
};
const isActive = (active) => {
  if (active == "home" && route.name == "country") {
    return true;
  }
  return route.path.includes(active);
};
const onToggleMenu = () => {
  isMenuShow.value = false;
};
const onClickLink = (route) => {
  if (drawer.value) {
    drawer.value.close();
  }
  isMenuShow.value = false;
  router.push(route);
};
const open = () => {
  if (drawer.value) {
    drawer.value.open();
  }
  isMenuShow.value = true;
};
const close = () => {
  if (drawer.value) {
    drawer.value.close();
  }
  isMenuShow.value = false;
};
const updateScroll = () => {
  scrollPosition.value = window.scrollY;
};
</script>

<style lang="postcss" >
@import "mmenu-light/dist/mmenu-light.css";

div.locale-selector {
  padding: 0 2rem;
}

.mm-ocd__content {
  @apply overflow-hidden rounded-e-3xl;
  -webkit-transition-duration: 0s !important;
  -o-transition-duration: 0s !important;
  transition-duration: 0s !important;
}
.mm-spn li {
  @apply font-replica-regular text-black transition-all duration-75;

  &.is-active {
    @apply text-primary;

    &:before {
      @apply text-primary opacity-100;
    }
    &:after {
      @apply text-primary ml-5 opacity-100 border-primary;
    }
  }
}
.mm-spn li:before {
  @apply text-black transition-all duration-75;
}
.mm-spn li:after {
  @apply bg-black opacity-10 transition-all duration-75;
}
.mm-spn li:hover {
  @apply text-primary font-replica-regular;

  &:before {
    @apply text-primary opacity-80;
  }
  &:after {
    @apply text-primary ml-5 opacity-100 border-primary;
  }
}
.mm-spn.mm-spn--navbar.mm-spn--main:after {
  @apply bg-white flex items-end text-left h-[120px] capitalize opacity-100 text-black font-replica-bold;
}
.mm-spn.mm-spn--light {
  @apply bg-white;
}
.mm-spn.mm-spn--navbar ul {
  --mm-spn-item-height: 62px;
  top: calc(var(--mm-spn-item-height) + 80px);
}
.mm-spn a {
  @apply text-lg;
}
.mm-spn li:after {
  @apply border-gray-400;
}

.mm-spn a:not(:last-child):after {
  @apply border-transparent;
}

.mm-spn.mm-spn--navbar:after {
  @apply font-bold uppercase bg-gray-100 text-black opacity-60;
}

.mm-spn.mm-spn--navbar ul:before {
  @apply border-transparent;
}

.mm-spn .locale-selector a {
  @apply !text-[10px] !w-auto !m-0 !p-0 !leading-4;
}
.mm-spn .locale-selector span {
  @apply !w-auto !m-0 !py-0.5 !px-1;
}
.locale-selector {
  @apply !px-0;
}
</style>
